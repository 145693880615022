@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  background-color: rgb(250 250 250 / 1);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .alert {
    @apply flex flex-col p-4 pt-2 w-full border-l-8;
  }

  .alert-title {
    @apply font-semibold italic text-lg;
  }

  .alert-content {
    @apply leading-tight text-sm;
  }

  .alert-info {
    @apply bg-blue-100 border-blue-500
  }

  .alert-info-title {
    @apply text-blue-500;
  }

  .alert-info-content {
    @apply text-blue-800;
  }

  .alert-warning {
    @apply bg-red-100 border-red-500
  }

  .alert-warning-title {
    @apply text-red-500;
  }

  .alert-warning-content {
    @apply text-red-800;
  }
}

@layer base {
  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-lg font-bold;
  }
  h4 {
    @apply underline text-lg;
  }
  h5{
    @apply text-lg;
  }
  h6{
    @apply text-base font-bold;
  }
}